import './App.scss';
import store from "./store/store";
import Router from "./routes/Router";
import {Provider} from "react-redux";

function App() {
    return (
        <Provider store={store}>
            <Router/>
        </Provider>
    );
}

export default App;
