import {Currency} from "../../models/Currency";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export interface CurrenciesState {
    currencies: Currency[] | undefined
}

const initialState: CurrenciesState = {
    currencies: undefined
};

export const currenciesSlice = createSlice({
    name: 'currenciesSlice',
    initialState,
    reducers: {
        setCurrenciesState: (state: CurrenciesState, action: PayloadAction<CurrenciesState>) => {
            state.currencies = action.payload.currencies;
        }
    }
});

export const {setCurrenciesState} = currenciesSlice.actions;

export default currenciesSlice.reducer;
