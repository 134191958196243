import Input from 'react-phone-number-input/input'
import {FieldHookConfig, useField} from "formik";

const PhoneInput = (props: FieldHookConfig<string>) => {
    const [field, meta, helpers] = useField(props.name);

    const {value} = meta;
    const {setValue} = helpers;

    const handleSetValue = (number: undefined) => {
        setValue(number);
    };

    return (
        <Input
            {...field}
            name={props.name}
            placeholder={props.placeholder}
            value={value}
            onChange={handleSetValue}/>
    )
}

export default PhoneInput
