import MainHeader from "../MainHeader/MainHeader";
import React, {FC, JSX} from "react";
import "./MainLayout.scss"

interface MainLayoutProps {
    children: JSX.Element
}

const MainLayout: FC<MainLayoutProps> = ({children}) => {
    return (
        <React.Fragment>
            <MainHeader/>
            <div className="main content-container">
                {children}
            </div>
        </React.Fragment>
    )
}

export default MainLayout
