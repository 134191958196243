import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {getCurrencies} from "../../@api/Currency";
import {Currency} from "../../models/Currency";
import {setCurrenciesState} from "../../store/reducers/currencies";

const Currencies = () => {
    const milliseconds = 1000 * 60 * 60;
    const currencies = useSelector((state: RootState) => state.currenciesSlice.currencies);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!currencies) setCurrencies();

        const interval = setInterval(() => {
            setCurrencies();
        }, milliseconds);

        return () => {
            clearInterval(interval);
        }
    }, [])

    const setCurrencies = () => {
        getCurrencies()
            .then((response) => {
                const tempCurrencies: Currency[] = [];

                response.data.forEach((currency: Currency) => {
                    if (currency.is_main) tempCurrencies.push(currency);
                });

                dispatch(setCurrenciesState({currencies: tempCurrencies}));
            })
            .catch((e: Error) => {
                console.log(e);
            });
    };

    return (
        <></>
    )
}

export default Currencies;
