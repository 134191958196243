import {useEffect, useState} from "react";
import "./MainHeader.scss"
import {Link} from "react-router-dom";
import Logo from "../../assets/images/header/logo.svg"
import Mail from "../../assets/images/header/mail.svg"
import Telegram from "../../assets/images/header/telegram.svg"
import Whatsapp from "../../assets/images/header/whatsapp.svg"
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";

const MainHeader = () => {
    const user = useSelector((state: RootState) => state.userSlice.user);
    const [name, setName] = useState<string>('User Name');
    const [company, setCompany] = useState<string>('Company Name');
    const [logo, setLogo] = useState<string>(Logo);

    useEffect(() => {
        if (user) {
            user.visible_name.includes("name") ? setName(user.name) : setName('');
            user.visible_name.includes("company_name") ? setCompany(user.company_name) : setCompany('');

            if (user.logo) {
                setLogo(process.env.REACT_APP_CLOUDFRONT_BASE_URL + user.logo);
            }
        }
    })

    return (
        <>
            <header className="header">
                <nav className="nav content-container">
                    <div className="nav__user">
                        <Link className="nav__user-logo" to="/">
                            <img src={logo} alt="logo"/>
                        </Link>
                        <div className="nav__user__data">
                            <div className="nav__user-name">{name}</div>
                            <div className="nav__user-company">{company}</div>
                        </div>
                    </div>
                    <div className="nav__social">
                        <a className="nav__social-mail" href={"mailto: " + user?.email}>
                            <img src={Mail} alt="mail"/>
                        </a>

                        {
                            user?.tg_name &&
                            <a className="nav__social-telegram" href={"https://t.me/" + user?.tg_name}
                               target="/blank">
                                <img src={Telegram} alt="telegram"/>
                            </a>
                        }

                        <a className="nav__social-whatsapp" href={"https://wa.me/" + user?.phone_number}
                           target="/blank">
                            <img src={Whatsapp} alt="whatsapp"/>
                        </a>
                    </div>
                </nav>
            </header>
        </>
    );
}

export default MainHeader;
