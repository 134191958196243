import axios from 'axios';
import {GlobalStatusCodesEnum} from "../enums/GlobalStatusCodesEnum";

export default axios.create({
    baseURL: process.env.REACT_APP_API_URL,

    headers: {
        "Content-Type": "application/json",
    },

    transformResponse: [function (data, headers) {
        let response;

        if (headers['content-type'] === 'application/pdf') {
            return data;
        }

        try {
            response = JSON.parse(data);
        } catch (e) {
            throw Error(`Error parsing response JSON data: ${JSON.stringify(e)}`);
        }

        if (response.statusCode === GlobalStatusCodesEnum.Success) {
            return response.data;
        } else {
            throw Error(`Request failed with reason: ${response.message}`);
        }
    }]
});
