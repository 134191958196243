import userStateReducer from './user';
import currenciesStateReducer from './currencies';

const reducers = {
    reducer: {
        userSlice: userStateReducer,
        currenciesSlice: currenciesStateReducer,
    }
};

export default reducers;
