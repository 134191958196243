import React, {FC, JSX} from "react";
import {Modal} from "react-bootstrap";
import "./AppModal.scss";

interface Props {
    title: string;
    component: JSX.Element;
    show: boolean
    showHandle: () => void;
}

const AppModal: FC<Props> = ({title, component, show, showHandle}) => {
    const handleClose = () => {
        showHandle();
    };

    return (
        <>
            <Modal centered={true} show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {component}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AppModal;
