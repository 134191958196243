import React, {FC} from 'react';

const ErrorPage: FC = () => {
    return (
        <div className="d-flex align-items-center justify-content-center vh-100">
            <h1>404</h1>
        </div>
    );
};

export default ErrorPage;
