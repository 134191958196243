export const getColor = (color: string) => {
    switch (color) {
        case 'white':
            return '333333';
        case 'black':
            return 'FFFFFF';
        case 'blue':
            return '3A4076';
        case 'red':
            return '373331';
        case 'green':
            return '313732';
    }
};

export const selectArrowSvg = (color: string | undefined) => {
    return `data:image/svg+xml;utf8,<svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Polygon 1" d="M5.86603 7.5C5.48113 8.16667 4.51887 8.16667 4.13397 7.5L0.669874 1.5C0.284974 0.833333 0.7661 -8.94676e-07 1.5359 -8.27378e-07L8.4641 -2.21695e-07C9.2339 -1.54397e-07 9.71503 0.833333 9.33013 1.5L5.86603 7.5Z" fill="%23${getColor(color ?? 'white')}"/></svg>`;
};

export const filtersBurgerSvg = (color: string | undefined) => {
    return `data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path id="Vector" d="M3 7H21M6 12H18M9 17H15" stroke="%23${getColor(color ?? 'white')}" stroke-width="1.5"/></svg>`;
};

export const actionSvg = (color: string | undefined) => {
    return `data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="%23${getColor(color ?? 'white')}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 6C12.5523 6 13 5.55228 13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5C11 5.55228 11.4477 6 12 6Z" stroke="%23${getColor(color ?? 'white')}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M12 20C12.5523 20 13 19.5523 13 19C13 18.4477 12.5523 18 12 18C11.4477 18 11 18.4477 11 19C11 19.5523 11.4477 20 12 20Z" stroke="%23${getColor(color ?? 'white')}" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;
};
