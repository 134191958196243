import {Currency} from "../models/Currency";

export const getCurrencySymbol = (currencies: Currency[] | undefined, code: string) => {
    const currency = currencies?.find((item) => {
        return item.code === code;
    });

    return currency?.currency_symbol;
};

export const convert = (currencies: Currency[] | undefined, from: string, to: string, amount: number) => {
    const fromValue = currencies?.find((item) => {
        return item.code === from;
    })?.value;

    const toValue = currencies?.find((item) => {
        return item.code === to;
    })?.value;

    if (fromValue && toValue) {
        return Math.round(toValue * amount / fromValue * 100) / 100;
    }

    return -1;
};
