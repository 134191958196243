import {getUser} from "../@api/User";
import {setUserState} from "../store/reducers/user";
import {Dispatch} from "react";
import {AnyAction} from "redux";

export const setUser = (dispatch: Dispatch<AnyAction>) => {
    const userDomain = window.location.host.split('.')[0];

    getUser(userDomain)
        .then((response) => {
            dispatch(setUserState({user: response.data}))
            document.documentElement.classList.add(response.data.color);
        })
        .catch((e: Error) => {
            console.log(e);
        });
};
