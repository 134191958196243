import {Form, Formik, FormikValues} from "formik";
import React, {ReactElement, useState} from "react";
import "./TableFilters.scss";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {filtersBurgerSvg} from "../../helpers/SvgTheming";
import {TextInput} from "../../models/Inputs/TextInput";
import InputSwitch from "../../ui/Inputs/InputSwitch/InputSwitch";

interface TableFiltersProps<T> {
    filterInitial: T,
    setDataFunction: (values: T) => void,
    inputsFirst: TextInput[],
    inputsSecond: TextInput[],
    inputsThird: TextInput[],
}

const TableFilters = <T extends unknown>({
                                             filterInitial,
                                             setDataFunction,
                                             inputsFirst,
                                             inputsSecond,
                                             inputsThird
                                         }: TableFiltersProps<T>): ReactElement => {
    const user = useSelector((state: RootState) => state.userSlice.user);
    const [filtersVisibility, setFiltersVisibility] = useState<boolean>(false);

    const handleFiltersVisibility = () => {
        const filters = document.getElementsByClassName('filters__wrapper-secondary')[0];

        filtersVisibility ? filters.classList.add('visually-hidden') : filters.classList.remove('visually-hidden');
        setFiltersVisibility(!filtersVisibility);
    };

    const handleFiltersSubmit = (values: FormikValues) => {
        if (values.to_date) {
            const userTimezoneOffset = values.to_date.getTimezoneOffset() * 60000;

            values.to_date = new Date(values.to_date.getTime() - userTimezoneOffset);
        }

        setDataFunction(values as T);
    };

    return (
        <>
            <Formik
                initialValues={filterInitial as FormikValues}
                onSubmit={(values) => {
                    handleFiltersSubmit(values);
                }}>
                <Form className="filters">
                    <div className="filters__wrapper-primary">
                        <div className="filters__wrapper">
                            {inputsFirst.map((value: TextInput) => (
                                InputSwitch(value)
                            ))}
                        </div>
                        <div className="filters__wrapper-buttons">
                            <button className="button button_submit" type="submit">
                                Submit
                            </button>
                            <button className="button" type="button" onClick={handleFiltersVisibility}>
                                <img src={filtersBurgerSvg(user?.color)} alt="Filters"/>
                                Filters
                            </button>
                        </div>
                    </div>
                    <div className="filters__wrapper-secondary visually-hidden">
                        <div className="filter-container">
                            {inputsSecond.map((value: TextInput) => (
                                <div key={value.name} className="w-100">
                                    {InputSwitch(value)}
                                </div>
                            ))}
                        </div>
                        <div className="filter-container">
                            {inputsThird.map((value: TextInput) => (
                                InputSwitch(value)
                            ))}
                        </div>
                    </div>
                </Form>
            </Formik>
        </>
    )
}

export default TableFilters
