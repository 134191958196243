export const downloadFile = (title: string, href: string) => {
    const link = document.createElement('a');

    link.href = href;
    link.setAttribute('download', title);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
};
